import React, { useEffect, useState } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import UserContextprovider from './Components/Context/Context';
import routers from './Components/Routes/routes';
import { Toaster } from 'react-hot-toast';
function App() {
  return (
    <div>
      <UserContextprovider>
        <RouterProvider router={routers} />
        <Toaster />
      </UserContextprovider>
    </div>
  );
}

export default App;
