import React from 'react'
import SliderHome from './sliderHome/slider'
import SubSliderMin from './SubSliderMin/SubSliderMin'
import Ads from './Ads/Ads'
import Latest_Products from './SliderProducts/latest_products'
import AdsProduct from './Ads/AdsProduct'
import ProductsLabtop from './SliderProducts/ProductsLabtop'
import Subfooter from '../Footer/Subfooter'

export default function Home() {
  return (
    <div>
      <SliderHome />
      <SubSliderMin />
      {/* <Ads /> */}
      <Latest_Products />
      <AdsProduct />
      <ProductsLabtop/>
      <Subfooter/>

    </div>
  )
}
