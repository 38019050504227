import React, { useState, useEffect, useContext } from 'react';
import i18n from '../../i18n';
import { UserContext } from '../Context/Context';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Search from '../Home/Search/Search';
import SearchInput from '../Home/Search/SearchInput';
import logo from '../../../src/logoCmask.png'
export default function TopNavbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { getdata, url } = useContext(UserContext);
  const [apiUrl, setApiUrl] = useState("https://dashboard.cmask.net/api/en");

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  let navigate = useNavigate()
  const { data, isError, isLoading } = useQuery('fetchCategories', getdata);
  const [numbercart, setnumbercart] = useState([])

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    if (selectedLanguage === language) return; // تجنب إعادة تحميل الصفحة إذا لم يتغير شيء

    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);

    // تحديث رابط API بناءً على اللغة المختارة
    setApiUrl(`https://dashboard.cmask.net/api/${selectedLanguage}`);

    // تحديث الرابط في المتصفح
    const currentPath = window.location.pathname.replace(/^\/(ar|en)/, '');
    navigate(`/${selectedLanguage}${currentPath}`);
  };

  // تحديث API عند تحميل الصفحة وفق اللغة المحفوظة
  useEffect(() => {
    const savedLang = localStorage.getItem("language") || "en";
    setApiUrl(`https://dashboard.cmask.net/api/${savedLang}`);
  }, []);


  useEffect(() => {
    function getData() {
      let token = localStorage.getItem("token")
      axios.get(`${url}/cart/show-cart?lang=ar`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
        .then(response => setnumbercart(response.data?.data))
        .catch(error => console.log(error));
    }
    getData();
  }, [numbercart]);



  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  const handleLogout = () => {
    localStorage.removeItem("token"); // حذف التوكن
    navigate("/login"); // إعادة التوجيه إلى صفحة تسجيل الدخول
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    if (value === "profile") {
      navigate("/profile"); // توجيه لصفحة الملف الشخصي
    } else if (value === "logout") {
      localStorage.removeItem("token"); // حذف التوكن
      navigate("/login"); // إعادة التوجيه إلى تسجيل الدخول
    }
  };

  return (
    <div className="bg-[#1e2d7d]  position-fixed top-0 left-0 right-0 h-24">
      {/* سطح المكتب */}
      <div className="container mx-auto p-5 hidden md:block">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <div className="grid grid-cols-12 gap-0">
              <div className="col-span-2 text-center ">
                <Link to="/" className=''>
                  <img
                    src={logo}
                    alt="Logo"
                    className="h-[60%]"
                  />
                </Link>

              </div>
              <div className="col-span-10">
                <div className="flex">
                  <input
                    type="text"
                    placeholder="Search...."
                    className="w-full shadow border rounded py-3 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                  />
                  {/* <SearchInput onSearch={handleSearch} results={results} /> */}

                  <div className="bg-[#fff200] rounded-r-lg p-3">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* التحكم في اللغة / تسجيل الدخول / السلة */}
          <div className="col-span-4">
            <div className=" p-4 flex justify-end items-center">
              <select
                value={language}
                onChange={changeLanguage}
                className="text-sm outline-none mx-5 text-black px-5 py-1 rounded"
              >
                <option value="en">English</option>
                <option value="ar">العربية</option>
              </select>

              {/* {localStorage.getItem("token") ? <Link to={'/Profile'} className='text-lg mx-3 font-bold'>My Account</Link> : <>
                <div className="cursor-pointer text-lg mx-3"> <Link to='/login'>Login / Signup</Link> </div>
              </>} */}

              <>
                {localStorage.getItem("token") ? (
                  <select
                    className="border py-1 mx-5 rounded-md cursor-pointer"
                    onChange={handleSelectChange}
                    defaultValue=""
                  >
                    <option value="" disabled>My Account</option>
                    <option value="profile">Profile</option>
                    <option value="logout">Logout</option>
                  </select>
                ) : (
                  <a href="/login" className="text-sm mx-3 font-bold text-white">
                    Login / Signup
                  </a>
                )}
              </>


              <Link to={"/cart"} className="relative">
                <i className="fa-solid fa-cart-shopping text-2xl text-white"></i>
                {/* <span className="absolute -top-2 font-bold -right-2 bg-[#fff200] text-blue-900 rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {numbercart?.count_products}
                </span> */}
                {/* <span className="font-bold ml-1">Cart</span> */}
              </Link>
              <Link to={"/favourites"} className="relative mx-4">
                <i class="fa-solid fa-heart text-2xl text-white"></i>
                {/* <span className="absolute -top-2 font-bold -right-2 bg-[#fff200] text-blue-900 rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {numbercart?.count_products}
                </span> */}
                {/* <span className="font-bold ml-1">Cart</span> */}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* للموبايل */}
      <div className="block md:hidden">
        <div className="flex justify-between items-center p-4 bg-[#1e2d7d] text-white">
          <div className="flex">
            <i className="fa-solid fa-cart-shopping text-lg"></i>
            <i className="fas fa-search mx-2"></i>
            <i className="fa-solid fa-user mx-2"></i>
          </div>

          <div className="flex">
            <img
              src="https://kimostore.net/cdn/shop/files/kimo-store_logo_en_75x@2x.png?v=1678809421"
              alt="Logo"
              className="h-8 mx-8"
            />
            <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
              <i className="fa-solid fa-bars text-2xl"></i>
            </button>
          </div>
        </div>

        {mobileMenuOpen && (
          <div className="bg-[#1e2d7d] text-white p-4 relative z-10">
            <ul className="space-y-4">

              {isLoading && <li>Loading categories...</li>}
              {isError && <li>Error fetching categories.</li>}
              {data?.data?.data?.categories?.map((value, i) => (
                <li key={i} className="hover:underline cursor-pointer">
                  {value.name}
                </li>
              ))}
              <li className="cursor-pointer">Login / Signup</li>
              <li className="cursor-pointer flex items-center">
                <i className="fa-solid fa-cart-shopping text-lg"></i>
                <span className="ml-2">Cart ({numbercart?.count_products})</span>
              </li>
              <li>
                <select
                  value={language}
                  onChange={changeLanguage}
                  className="text-sm outline-none bg-white text-black px-5 py-1 rounded"
                >
                  <option value="en">English</option>
                  <option value="ar">العربية</option>
                </select>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
