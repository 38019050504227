// src/Components/Routes/routes.js

import { createBrowserRouter } from 'react-router-dom';
import Layout from '../Layout/Layout';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import HomeDashboard from '../Dashboard/Home';
import Login from '../Auth/Login';
import NotFound from '../NotFound/NotFound';
import LoginDashboard from "../Dashboard/Auth/Login/Login";
import MyDataTable from '../DataTable/DataTable';
import Slider from '../Dashboard/Slider/Slider';
import About from '../Dashboard/About/About';
import Test from '../Test';
import Rejster from '../Auth/Rejster';
import Profile from '../User/Profile';
import Productsdetaies from '../Home/products/productsdetaies/productsdetaies';
import DisplayCart from '../Cart/DisplayCart/DisplayCart';
import ShowdataFavourite from '../Favourite/ShowFavourite';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Search from '../Home/Search/Search';
import SearchInput from '../Home/Search/SearchInput';

const routers = createBrowserRouter([
  {  
    path: "", 
    element: <Layout/>,  // General layout for non-dashboard pages
    children: [
      { path: "/", element: <Home/> },
      { path: "Login", element: <Login/> },
      { path: "MyDataTable", element: <MyDataTable/> },
      { path: "test", element: <Test/> },
      { path: "Rejster", element: <Rejster/> },
      { path: "Profile", element:<ProtectedRoute> <Profile/>   </ProtectedRoute>  },
      { path: "Cart", element: <DisplayCart/> },
      { path: "search", element: <SearchInput/> },
      { path: "favourites", element:<ProtectedRoute> <ShowdataFavourite/></ProtectedRoute> },
      { path: "productsdetaies/:id", element: <Productsdetaies/> },
      { path: "*", element: <NotFound/> }
    ] 
  },
  { 
    path: "Dashboard", 
    element: <Dashboard/>,  // Dashboard layout for dashboard pages
    children: [
      { path: "", element: <LoginDashboard/> }, 
      { path: "homeDashboard", element: <HomeDashboard/> },
      { path: "slider", element: <Slider/> },
      { path: "About", element: <About/> },
    ]
  }
]);
export default routers;