import React from 'react'
import DarkModeToggle from '../../Navbar/darkmode'

export default function About() {
  return (
  <>
  
    <div className='bg-gradient-to-r from-slate-700 to-slate-100 animate-pulse  dark:bg-black '>
      <button className='btncastom list-outside animate animate-pulse'>Welcome</button>
    </div>
    
      <div className='text-[50px] dark:bg-black dark:text-white'>dark</div>
    </>
  )
}
