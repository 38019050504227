import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../Context/Context";
import axios from "axios";
import toast from "react-hot-toast";

export default function Profile() {
    const { url } = useContext(UserContext);
    const [profile, setProfile] = useState({ name: "", email: "", phone: "", address: "" });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // حالة التحكم في إظهار المودال

    // بيانات تغيير كلمة المرور
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            setError("No token found. Please log in.");
            setLoading(false);
            return;
        }

        async function fetchProfile() {
            try {
                const response = await axios.get(`${url}/profile`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setProfile(response.data?.data);
            } catch (err) {
                setError(err.response?.data?.message || "Failed to fetch profile.");
            } finally {
                setLoading(false);
            }
        }

        fetchProfile();
    }, [url]);

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("token");
            await axios.put(`${url}/profile/update`, profile, {
                headers: { Authorization: `Bearer ${token}` }
            });
            toast.success("Profile updated successfully!");
            setEditing(false);
        } catch (err) {
            setError(err.response?.data?.message || "Failed to update profile.");
        }
    };

    const handleChangePassword = async () => {
        if (newPassword.length < 6) {
            setPasswordError("Password must be at least 6 characters long.");
            return;
        }
        if (newPassword !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            return;
        }

        try {
            const token = localStorage.getItem("token");
            await axios.put(
                `${url}/profile/change-password`,
                { old_password: oldPassword, password: newPassword, password_confirmation: confirmPassword },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            toast.success("Password updated successfully!");
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setPasswordError("");
            setIsModalOpen(false); // إغلاق المودال بعد التحديث
        } catch (err) {
            setPasswordError(err.response?.data?.message || "Failed to change password.");
        }
    };

    return (
        <div className="max-w-md mx-auto p-5">
            <h2 className="text-2xl font-bold mb-4 text-center">Profile</h2>

            {loading ? (
                <p className="text-center">Loading...</p>
            ) : error ? (
                <div className="text-red-500 text-center">{error}</div>
            ) : (
                <div className="bg-gray-100 p-4 rounded shadow mx-auto">
                    <label className="block mt-4 mx-4">
                        <strong>Name:</strong>
                        <input
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            name="name"
                            value={profile.name}
                            onChange={(e) => setProfile({ ...profile, [e.target.name]: e.target.value })}
                            disabled={!editing}
                        />
                    </label>

                    <label className="block mt-4 mx-4">
                        <strong>Email:</strong>
                        <input
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            name="email"
                            value={profile.email}
                            disabled
                        />
                    </label>
                    <label className="block mt-4 mx-4">
                        <strong>phone:</strong>
                        <input
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            name="phone"
                            value={profile.phone}
                            disabled
                        />
                    </label>
                    <label className="block mt-4 mx-4">
                        <strong>address:</strong>
                        <input
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            name="address"
                            value={profile.address}
                            disabled
                        />
                    </label>

                    <div className="flex justify-between mt-4">
                        {editing ? (
                            <>
                                <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={handleSave}>
                                    Save
                                </button>
                                <button className="bg-gray-500 text-white px-4 py-2 rounded-md" onClick={() => setEditing(false)}>
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button className="bg-blue-500 text-white px-4 py-2 rounded-md w-full" onClick={() => setEditing(true)}>
                                Edit Profile
                            </button>
                        )}
                    </div>

                    {/* زر فتح المودال */}
                    <div
                        className=" text-blue-700 font-bold cursor-pointer text-center px-4 py-2 rounded-md  mt-4"
                        onClick={() => setIsModalOpen(true)}
                    >
                        Change Password
                    </div>

                    {/* Modal لتغيير كلمة المرور */}
                    {isModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white p-6 rounded-md shadow-md w-96">
                                <h3 className="text-lg font-semibold text-center">Change Password</h3>

                                <label className="block mt-4">
                                    <strong>Old Password:</strong>
                                    <input
                                        type="password"
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                </label>

                                <label className="block mt-4">
                                    <strong>New Password:</strong>
                                    <input
                                        type="password"
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </label>

                                <label className="block mt-4">
                                    <strong>Confirm Password:</strong>
                                    <input
                                        type="password"
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </label>

                                {passwordError && <p className="text-red-500 text-sm text-center mt-2">{passwordError}</p>}

                                <div className="flex justify-between mt-4">
                                    <button
                                        className="bg-green-500 text-white px-4 py-2 rounded-md"
                                        onClick={handleChangePassword}
                                    >
                                        Update Password
                                    </button>
                                    <button
                                        className="bg-gray-500 text-white px-4 py-2 rounded-md"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
