import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../Context/Context';
import Slider from 'react-slick';
import { useQuery } from 'react-query';
import { Circles, RevolvingDot } from 'react-loader-spinner';


export default function SubSliderMin() {
   const { getdata } = useContext(UserContext);
    const [date, setDate] = useState(null);

    const { data, isError, isLoading } = useQuery("fuctionerproduct", getdata);

    useEffect(() => {
        if (data) {
            setDate(data?.data?.data);
        }
    }, [data]);

  if (isLoading) return <div className='flex justify-center mt-5 mb-5'>
<RevolvingDot
  height="80"
  width="80"
  color="#1E2D7D"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

  </div>
    if (isError) return <div>Error occurred while fetching data...........</div>;

    // إعدادات السلايدر
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6, // عدد العناصر المرئية
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 4 },
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 3 },
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 2 },
            }
        ]
    };


    return (
        <div className='container mt-10'>
            <Slider {...settings}>
                {data?.data?.data?.categories?.map((item, index) => (
                    <div key={index} className="p-2">
                        <img src={item?.image_name} alt={`Category ${index}`} className="w-full h-[100px] rounded-lg" />
                    </div>
                ))}
            </Slider>
        </div>
    );
}
