import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../Context/Context';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import SliderDetalies from './SliderDetalies';

export default function ProductsDetails() {
    const { url } = useContext(UserContext);
    const [data, setData] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [sliderdata, setsliderdata] = useState([]);
    let { id } = useParams();

    // Scroll to top on component mount
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    useEffect(() => {
        function getData() {
            axios.post(`${url}/products/show/${id}`)
                .then(response => {
                    setData(response.data?.data?.product);
                    setsliderdata(response.data?.data?.relatedProducts);
                })
                .catch(error => console.log(error));
        }
        getData();
    }, [id, url]);

    const handleAddToCart = () => {
        let token = localStorage.getItem("token");

        axios.post(`${url}/cart/add-to-cart/${id}`,
            { quantity },
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then(response => {
                toast.success("تمت إضافة المنتج إلى السلة بنجاح!");
            })
            .catch(error => {
                toast.error("حدث خطأ أثناء إضافة المنتج إلى السلة.");
            });
    };

    return (
        <div>
            <div className='flex mt-4 container mx-auto px-5'>
                <p className='font-bold text-gray-500 text-sm'> <Link to={"/"}>Home</Link> </p>
                <p className='font-bold text-gray-500 text-sm mx-3'><i className="fa-solid fa-chevron-right"></i></p>
                <p className='font-bold text-gray-500 text-sm'>All products</p>
                <p className='font-bold text-gray-500 text-sm mx-3'><i className="fa-solid fa-chevron-right"></i></p>
                <p className='font-bold text-gray-500 text-sm'>{data?.name}</p>
            </div>

            <div className='mt-5 container mx-auto mb-10'>
                <div className='grid grid-cols-2'>
                    <div className='border'>
                        <div className='mx-8 mt-4'>
                            <img className='w-full h-[70vh]' src={data?.image} alt={data?.name} />
                        </div>
                    </div>
                    <div className='border'>
                        <div className='mx-8 mt-4'>
                            <h5 className='font-bold text-2xl text-gray-500'>{data?.description}</h5>
                            <div className='mt-4 flex justify-between'>
                                <p className='font-bold text-sm text-gray-600'>PORODO</p>

                                <div className='flex'>
                                    <div className='rounded-full mx-2'>
                                        <div className=' hover:bg-color cursor-pointer w-[30px] h-[30px] bg-gray-400 text-white rounded-full flex justify-center pt-[7px]'><i className="fa-brands fa-facebook-f"></i></div>
                                    </div>
                                    <div className='rounded-full mx-2'>
                                        <div className=' hover:bg-color cursor-pointer w-[30px] h-[30px] bg-gray-400 text-white rounded-full flex justify-center pt-[7px]'><i className="fa-solid fa-envelope"></i></div>
                                    </div>
                                    <div className='rounded-full mx-2'>
                                        <div className=' hover:bg-color cursor-pointer w-[30px] h-[30px] bg-gray-400 text-white rounded-full flex justify-center pt-[7px]'><i className="fa-brands fa-tiktok"></i></div>
                                    </div>
                                    <div className='rounded-full mx-2'>
                                        <div className=' hover:bg-color cursor-pointer w-[30px] h-[30px] bg-gray-400 text-white rounded-full flex justify-center pt-[7px]'><i className="fa-brands fa-twitter"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className='border mt-5'></div>
                            <div className='flex justify-between'>
                                <div className='flex'>
                                    <del className='mt-4 mx-3'>{data?.old_price}</del>
                                    <h5 className='font-bold text-2xl text-color mt-3'>{data?.price}</h5>
                                </div>
                                <div className='flex mt-5'>
                                    <div className='h-[10px] w-[10px] bg-green-400 rounded-full mt-2'></div>
                                    <p className='font-bold mx-2 text-green-500'>In stock</p>
                                </div>
                            </div>

                            {/* 🔄 التحكم في الكمية */}
                            <div className='mt-10 flex justify-between'>
                                <p className='font-bold text-gray-600'>Quantity:</p>
                                <div className="flex justify-center w-1/5">
                                    <button onClick={() => setQuantity(prev => Math.max(prev - 1, 1))}>
                                        <svg className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                            <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                        </svg>
                                    </button>
                                    <input className="mx-2 border text-center w-8" type="text" value={quantity} readOnly />
                                    <button onClick={() => setQuantity(prev => prev + 1)}>
                                        <svg className="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                                            <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            {/* 🛒 زر الإضافة إلى السلة */}
                            <div className='mt-2 mb-2'>
                                {localStorage.getItem("token") ? (
                                    <button
                                        className='w-full bg-color text-white py-2 mt-4 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700'
                                        onClick={handleAddToCart}
                                    >
                                        <i className="fa-solid fa-cart-plus"></i> Add To Cart
                                    </button>
                                ) : (
                                    <Link to="/login">
                                        <button
                                            className='w-full bg-color text-white py-2 mt-4 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700'
                                        >
                                            <i className="fa-solid fa-cart-plus"></i> Add To Cart
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SliderDetalies sliderdata={sliderdata} />
        </div>
    );
}