import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
// import { UserContext } from '../Context/Context';
import Slider from 'react-slick';
import { UserContext } from '../../Context/Context';
import { Audio, Circles, RevolvingDot } from 'react-loader-spinner';

export default function SliderHome() {
  let { getdata } = useContext(UserContext)
  const [sliderdats, setsliderdats] = useState([])


  const { data, isError, isLoading } = useQuery("fuctionerproduct", getdata);
  useEffect(() => {
    if (data?.data?.data?.slider) {
      setsliderdats(data.data.data.slider);
    }
  }, [data])


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  if (isLoading) return <div className='flex justify-center mt-5 mb-5'>
<RevolvingDot
  height="80"
  width="80"
  color="#1E2D7D"
  ariaLabel="circles-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />

  </div>
  if (isError) return <p>Loading</p>

  return (
    <div className='container mx-auto mt-5'>
      <Slider {...settings}>
        {sliderdats?.map((slider) => (
          <img key={slider.id} src={slider?.image_name} />
        ))}
      </Slider>
    </div>
  )
}
