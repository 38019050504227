import React, { useState } from "react";

export default function SearchInput({ onSearch }) {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchClick = () => {
        if (searchTerm.trim() !== "") {
            onSearch(searchTerm);
        }
    };

    return (
        <div className="flex gap-2 items-center border p-2 rounded-lg">
            <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="ابحث عن منتج..."
                className="p-2 border rounded-lg flex-1"
            />
            <button
                onClick={handleSearchClick}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
                بحث
            </button>
        </div>
    );
}
