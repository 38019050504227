import axios from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Audio } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { UserContext } from '../Context/Context';

export default function Register() {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { url } = useContext(UserContext);
    let navigate = useNavigate(); // Corrected naming

    async function register(values) {
        setIsLoading(true);
        try {
            let { data } = await axios.post(`${url}/register`, {
                ...values,
                password_confirmation: values.repassword, // Send password confirmation
            });

            if (data.success) {
                navigate('/login'); // Redirect to login after successful registration
            } else {
                setError(data.message || 'حدث خطأ أثناء التسجيل');
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
        }
        setIsLoading(false);
    }

    let phoneRegex = /^[0-9]{11}$/;

    let validationSchema = yup.object({
        name: yup.string()
            .min(3, "يجب أن يكون الاسم على الأقل 3 أحرف")
            .max(10, "يجب ألا يتجاوز الاسم 10 أحرف")
            .required("الاسم مطلوب"),

        email: yup.string()
            .email("يجب إدخال بريد إلكتروني صالح")
            .required("البريد الإلكتروني مطلوب"),

        phone: yup.string()
            .matches(phoneRegex, "رقم الهاتف غير صالح")
            .required("رقم الهاتف مطلوب"),

        password: yup.string()
            .matches(/^[A-Z][a-z0-9]{5,10}$/, "يجب أن تبدأ كلمة المرور بحرف كبير وتحتوي على 6-11 حرفًا وأرقام")
            .required("كلمة المرور مطلوبة"),

        repassword: yup.string()
            .oneOf([yup.ref("password")], "كلمتا المرور غير متطابقتين")
            .required("إعادة كلمة المرور مطلوبة")
    });

    let formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            password: "",
            repassword: "",
        },
        validationSchema,
        onSubmit: register
    });

    return (
        <div className='w-96 mx-auto mt-5'>
            <form onSubmit={formik.handleSubmit} className='p-8'>
                {error && <div className='alert alert-danger p-3'>{error}</div>}

                {/* Name Input */}
                <div className='relative z-0 w-full mb-5 group'>
                    <input
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        name="name"
                        id="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placeholder=''
                    />
                    <label
                        htmlFor="name"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all">
                        Full Name
                    </label>
                    {formik.errors.name && formik.touched.name && <div className="bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.name}</div>}
                </div>

                {/* Email Input */}
                <div className='relative z-0 w-full mb-5 group'>
                    <input
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        type="email"
                        name="email"
                        id="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder=''
                    />
                    <label htmlFor="email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all">
                        Email Address
                    </label>
                    {formik.errors.email && formik.touched.email && <div className="bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.email}</div>}
                </div>

                {/* Phone Input */}
                <div className='relative z-0 w-full mb-5 group'>
                    <input
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        type="tel"
                        name="phone"
                        id="phone"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        placeholder=''
                    />
                    <label htmlFor="phone" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all">
                        Phone Number
                    </label>
                    {formik.errors.phone && formik.touched.phone && <div className="bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.phone}</div>}
                </div>

                {/* Password Input */}
                <div className='relative z-0 w-full mb-5 group'>
                    <input
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        type="password"
                        name="password"
                        id="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        placeholder=''
                    />
                    <label htmlFor="password" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all">
                        Password
                    </label>
                    {formik.errors.password && formik.touched.password && <div className="bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.password}</div>}
                </div>

                {/* Confirm Password Input */}
                <div className='relative z-0 w-full mb-5 group'>
                    <input
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        type="password"
                        name="repassword"
                        id="repassword"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.repassword}
                        placeholder=''
                    />
                    <label htmlFor="repassword" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all">
                        Confirm Password
                    </label>
                    {formik.errors.repassword && formik.touched.repassword && <div className="bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.repassword}</div>}
                </div>


                <div className='text-center mt-2'>
                    <p className='text-xs font-bold'>
                        
                    This site is protected by hCaptcha and the hCaptcha Privacy Policy and Terms of Service apply.
                        </p>

                    <div className='mt-4 flex'>
                        New customer? <Link to={"/login"} className='font-bold text-blue-500 mx-2'> Login here</Link>
                    </div>
                </div>

            </form>
        </div>

    );
}
