import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useQuery } from "react-query";
import { UserContext } from "../Context/Context";
import i18n from "../../i18n";
import { FaAngleDown, FaAngleRight } from "react-icons/fa"; // Import arrow icons
import { FallingLines } from "react-loader-spinner";

export default function Navbar() {
  const { t } = useTranslation();
  const { getdata } = useContext(UserContext);

  // Manage language and theme states
  const [language, setLanguage] = useState(localStorage.getItem("language") || "en");
  const [mymode, setMymode] = useState(localStorage.getItem("theme") || "light");

  // State to track the active submenu
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  // Update i18n when language changes
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  // Fetch categories using react-query
  const { data, isError, isLoading } = useQuery("fuctionerproduct", getdata);

  // Toggle theme
  const toggleTheme = () => {
    setMymode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("theme", newMode);
      return newMode;
    });
  };

  // Create a theme for Material UI
  const darkTheme = createTheme({
    palette: {
      mode: mymode,
    },
  });

  // Handle language changes
  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  // Handle click on a category to toggle submenu
  const handleClick = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <nav className="bg-gray-200 hidden md:block">
        {/* Desktop Navbar */}
        <div className="container mx-auto flex justify-between items-center p-4">
          <div className="flex items-center">
            {/* Navigation Links */}
            <ul className="flex space-x-4">
              {isLoading ? (
                <li><FallingLines
                visible={true}
                height="80"
                width="80"
                color="#1E2D7D"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                /></li>
              ) : isError ? (
                <li>Error loading categories</li>
              ) : (
                data?.data?.data?.categories?.map((category, i) => (
                  <li key={i} className="relative cursor-pointer">
                    {/* Main category item */}
                    <div
                      onClick={() => handleClick(i)}
                      className="hover:underline flex items-center"
                    >
                      {category.name}
                      {/* Add arrow icon if there's a submenu */}
                      {category.sup_department && category.sup_department.length > 0 && (
                        <FaAngleDown className="ml-1" />
                      )}
                    </div>

                    {/* Submenu for `sup_department` */}
                    {category.sup_department &&
                      category.sup_department.length > 0 &&
                      activeSubmenu === i && (
                        <ul className="absolute left-0 mt-2 bg-white border border-gray-300 shadow-lg z-50 min-w-[200px]">
                          {category.sup_department.map((sub, j) => (
                            <li
                              key={j}
                              className="relative px-4 py-2 hover:bg-gray-200 cursor-pointer flex items-center justify-between"
                              onClick={(e) => e.stopPropagation()} // Prevent event bubbling
                            >
                              <span>{sub.name}</span>
                              {/* Add arrow icon if there's a nested submenu */}
                              {sub.children && sub.children.length > 0 && (
                                <FaAngleRight className="ml-2" />
                              )}

                              {/* Submenu for `children` if exists */}
                              {sub.children && sub.children.length > 0 && (
                                <ul className="absolute left-full top-0 bg-white border border-gray-300 shadow-lg z-50 min-w-[200px]">
                                  {sub.children.map((child, k) => (
                                    <li
                                      key={k}
                                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                    >
                                      {child.name}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </nav>
    </ThemeProvider>
  );
}