import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../Context/Context';
import Favourite from '../../../Favourite/Favourite';

// مكونات الأسهم
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} bg-blue-600 rounded-full flex items-center justify-center`}
            style={{ ...style, display: 'block', right: '10px', zIndex: 10 }}
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-5 h-5"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} bg-blue-600 rounded-full flex items-center justify-center`}
            style={{ ...style, display: 'block', left: '10px', zIndex: 10 }}
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-5 h-5"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
        </div>
    );
}

export default function SliderDetalies({ sliderdata }) {
    const { getdata } = useContext(UserContext);
    const [latestProducts, setLatestProducts] = useState([]);

    // Fetch data using React Query
    const { data, isError, isLoading } = useQuery("latestProducts", getdata);

    useEffect(() => {
        if (data) {
            setLatestProducts(data?.data.data?.products_viewed || []);
            // console.log(data?.data.data.products);

        }
    }, [data]);

    // Slider settings
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 2, slidesToScroll: 1 },
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
        ],
    };

    if (isLoading) return <div className="text-center">Loading...</div>;
    if (isError) return <div className="text-center text-red-500">Failed to load products.</div>;

    return (
        <div>
            {/* Header Section */}
            <div className="bg-color rounded-t-xl mt-5">
                <div className="container mx-auto">
                    <div className="flex justify-between">
                        <h2 className="fw-bold p-4 text-white">Related Products</h2>
                        <h2 className="fw-bold p-4 text-white cursor-pointer">View all</h2>
                    </div>
                </div>
            </div>

            {/* Product Slider */}
            <div className='container mx-auto'>
                <Slider {...settings} className="p-5">
                    {sliderdata?.map((product) => (
                        <div key={product.id} className="mx-auto border border-gray-200 rounded-lg shadow-md">
                             <h5 className='mx-5 mt-2'>  <Favourite id={product.id}/>  </h5>
                            <Link to={`/Productsdetaies/${product?.id}`}>
                                <div className="p-3">
                                    <img
                                        src={product.image || "https://via.placeholder.com/300"}
                                        alt={product.name || "Product Image"}
                                        className="w-full h-48 object-cover rounded-t-lg"
                                    />
                                </div>
                                <div className="p-4">
                                    <h3 className="text-gray-500 text-sm uppercase font-medium">{product.brand || "Brand"}</h3>
                                    <h6 className="font-bold text-gray-500 mt-1 list-outside">
                                        {product.name
                                            ? product.name.split(" ").slice(0, 3).join(" ") + (product.name.split(" ").length > 10 ? "..." : "")
                                            : "Product Name"}
                                    </h6>

                                    <p className="text-green-600 text-xl font-bold mt-2">
                                        {product.price ? `${product.price} EGP` : "Price not available"}
                                    </p>
                                    <div className="flex">
                                        <div className={`h-[10px] w-[10px] rounded-full mt-2 mx-2 ${product.inStock ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        <p className={`text-sm mt-1 ${product.inStock ? 'text-green-500' : 'text-red-500'}`}>
                                            {product.inStock ? "In stock" : "Out of stock"}
                                        </p>
                                    </div>
                                    {/* <button className="w-full bg-color text-white py-2 mt-4 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 3h2l.879 4.394M7 13h10l1.122-5.606M5.5 20.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm11.5-1.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-10-6.5h11.5M8 18H16"
                                        />
                                    </svg>
                                    Add to cart
                                </button> */}
                                </div>

                            </Link>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}
