

import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom'
import { Offline, Online } from 'react-detect-offline'

import offlineimg from "../../Img/offline.jpg"
import TobNavbar from '../TobNavbar/TobNavbar'

export default function Layout() {
  return <>

<TobNavbar/>
    <Navbar />
    <Outlet />
    <Footer />
   

  </>
}
