import axios from 'axios';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Audio } from 'react-loader-spinner';
import * as yup from 'yup';
import { UserContext } from '../Context/Context';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { url } = useContext(UserContext);
  let navigate = useNavigate()
  function handleLogin(values) {
    setIsLoading(true);
    axios.post(`${url}/login`, values)
      .then((response) => {
        // console.log("Login successful:", response.data.data.token);
        toast.success("Authenticated")
        localStorage.setItem("token", response.data.data.token)
        navigate("/")
        // تخزين بيانات المستخدم إذا لزم الأمر
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.response?.data?.message || "حدث خطأ أثناء تسجيل الدخول");
      });
  }
  const validationSchema = yup.object({
    phone: yup.string()
      // .matches(/^\+9663\d{8}$/, "رقم الهاتف يجب أن يبدأ بـ +9663 ويتبعه 8 أرقام")
      .required("رقم الهاتف مطلوب"),
    password: yup.string()
      .min(6, "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل")
      .required("كلمة المرور مطلوبة")
  });


  const formik = useFormik({
    initialValues: {
      phone: "",
      password: ""
    },
    validationSchema,
    onSubmit: handleLogin
  });

  return (
    <div className="container">
      <form onSubmit={formik.handleSubmit} className="w-full max-w-sm mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
        {error && <div className="bg-red-100 text-red-700 p-3 rounded-md mb-4">{error}</div>}

        {/* رقم الهاتف */}
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 font-medium mb-1"> Phone:</label>
          <input
            name="phone"
            id="phone"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={formik.handleChange}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            type="tel"
          />

          {formik.errors.phone && formik.touched.phone && (
            <div className="bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.phone}</div>
          )}
        </div>

        {/* كلمة المرور */}
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 font-medium mb-1"> Password:</label>
          <input
            type="password"
            name="password"
            id="password"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
          />
          {formik.errors.password && formik.touched.password && (
            <div className=" bg-rose-400 text-center p-1 rounded-lg text-white text-sm mt-1">{formik.errors.password}</div>
          )}
        </div>

        {/* زر تسجيل الدخول مع التحميل */}
        <div className="flex justify-center mt-4">
          {isLoading ? (
            <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
          ) : (
            <button
              disabled={!(formik.isValid && formik.dirty)}
              className="w-full bg-color text-white py-2 rounded-md font-medium hover:bg-blue-600 transition disabled:bg-gray-400"
              type="submit"
            >
              Login
            </button>
          )}
        </div>
        <div className='text-center mt-2'>
          <p className='text-xs font-bold'>This site is protected by hCaptcha and the hCaptcha Privacy Policy and Terms of Service apply.</p>
        
        <div className='mt-4 flex'>
        New customer? <Link to={"/Rejster"} className='font-bold text-blue-500 mx-2'> Create your account</Link>
        </div>
        </div>
      </form>


    </div>
  );
}
