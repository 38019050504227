import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../Context/Context';
import { useQuery } from 'react-query';
import Slider from "react-slick";
export default function Sliderhome() {
let {getdata} = useContext(UserContext)
const { data, isError, isLoading } = useQuery("fuctionerproduct", getdata);







  return (
    <div>
     
    </div>
  )
}
