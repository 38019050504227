import React, { useContext, useState } from 'react';
import { UserContext } from '../Context/Context';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

export default function Favourite({ id }) {
    const { url } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const token = localStorage.getItem("token");

    const clickFavourite = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                `${url}/favourites/${id}`, // URL
                {}, // Body data (if needed)
                {
                    headers: { Authorization: `Bearer ${token}` }, // Headers
                }
            );
            console.log(response.data);
            toast.success("تمت إضافة العنصر إلى المفضلة بنجاح!");
        } catch (error) {
            console.error(error);
            setError("حدث خطأ أثناء إضافة العنصر إلى المفضلة.");
            toast.error("حدث خطأ أثناء إضافة العنصر إلى المفضلة.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>

            {localStorage.getItem("token") ?          <button
                onClick={clickFavourite}
                disabled={loading}
                className='   '
            >
                {loading ? "جاري التحميل..." : <i class="fa-solid fa-heart"></i>}
            </button> :<Link to={"/login"}>
            <button
               
                className='   '
            >
                {loading ? "جاري التحميل..." : <i class="fa-solid fa-heart"></i>}
            </button>
            </Link> }

   
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    );
}