import React from 'react';

export default function Subfooter() {
  const brands = [
    {
      src: "https://kimostore.net/cdn/shop/files/hp_18e62cc5-0d0d-4d95-a39c-749c185efc73.jpg?v=1710359025&width=280",
      alt: "HP",
    },
    {
      src: "https://kimostore.net/cdn/shop/files/braun.png?v=1710361171",
      alt: "Braun",
    },
    {
      src: "https://kimostore.net/cdn/shop/files/sony.png?v=1710361303",
      alt: "Sony",
    },
    {
      src: "https://kimostore.net/cdn/shop/files/apple_2f683f9f-87f6-4a45-8570-32f42d0dc6d6.png?v=1710361397",
      alt: "Apple",
    },
    {
      src: "https://kimostore.net/cdn/shop/files/canon.png?v=1710361899",
      alt: "Canon",
    },
    {
      src: "https://kimostore.net/cdn/shop/files/huwawi.png?v=1710361947",
      alt: "Huawei",
    },
    {
      src: "https://kimostore.net/cdn/shop/files/lenovo.png?v=1710361947",
      alt: "Lenovo",
    },
  ];

  return (
    <div>
      <div className="text-center mt-8">
        <h1 className="text-[#1e2d7d] font-bold text-2xl">Shop By brands</h1>
        <div className="grid grid-cols-7 gap-4 p-4">
          {brands.map((brand, index) => (
            <div
              key={index}
              className="flex justify-center items-center"
            >
              <img
                className="w-24 h-24 object-contain transition-transform duration-300 ease-in-out hover:scale-110"
                src={brand.src}
                alt={brand.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}