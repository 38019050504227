import React from 'react';

export default function AdsProduct() {
  return (
    <div className="container mx-auto mt-5">
      <div className="grid grid-cols-3 gap-4">
        
        <div className="overflow-hidden">
          <img
            src="https://kimostore.net/cdn/shop/files/1_6.png?v=1714922847&width=400"
            alt="Ad 1"
            className="transition-transform duration-300 ease-in-out transform hover:scale-110"
          />
        </div>

        <div className="overflow-hidden">
          <img
            src="https://kimostore.net/cdn/shop/files/3_4.png?v=1714922847&width=400"
            alt="Ad 2"
            className="transition-transform duration-300 ease-in-out transform hover:scale-110"
          />
        </div>


        <div className="overflow-hidden">
          <img
            src="https://kimostore.net/cdn/shop/files/2_4.png?v=1714922846&width=400"
            alt="Ad 3"
            className="transition-transform duration-300 ease-in-out transform hover:scale-110"
          />
        </div>
      </div>


      <div className="grid grid-cols-1 mt-4">
        <img
          src="https://kimostore.net/cdn/shop/files/Laptops.png?v=1714921796&width=1200"
          alt="Large Ad"
          className="w-full transition-transform duration-300 ease-in-out transform hover:scale-105"
        />
      </div>
    </div>
  );
}
