import axios from "axios";
import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export let UserContext = createContext()

export default function UserContextprovider(props) {

    const [counter, setcounter] = useState(0)
    const [sherdata, setsherdata] = useState([])

    function childrenCount() {
        setcounter(Math.random())
    }
    useEffect(() => {
        setsherdata(data?.data?.data)
    }, [])

    let  lang  = useParams();

  
    


    let url = "https://dashboard.cmask.net/api"

    function getdata() {
        return axios.post(`${url}/home`)
    }


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [date, setdata] = useState([])

    useEffect(() => {
        const token = localStorage.getItem("token");

        const clickFavourite = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(
                    `https://dashboard.cmask.net/api/favourites`, // URL
                    {}, // Body data (if needed)
                    {
                        headers: { Authorization: `Bearer ${token}` }, // Headers
                    }
                );
                setdata(response.data?.data);
                // toast.success("تمت إضافة العنصر إلى المفضلة بنجاح!");
            } catch (error) {
                console.error(error);
                setError("حدث خطأ أثناء إضافة العنصر إلى المفضلة.");
                // toast.error("حدث خطأ أثناء إضافة العنصر إلى المفضلة.");
            } finally {
                setLoading(false);
            }
        };
        clickFavourite()
        // console.log(date);
    }, [])



    let { data, isError, isLoading, isFetching } = useQuery("fuctionerproduct", getdata)
    return <UserContext.Provider value={{ counter, childrenCount, url, getdata, sherdata, date }}>
        {props.children}
    </UserContext.Provider>
}